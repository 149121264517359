import React from "react";
import backgroundImage2 from "../assets/backgroundImage2.webp";
import mobileGroupImage from "../assets/mobileGroup1.webp";
import GetTheAppBtn from "./GetTheAppBtn";
export default function Section5() {
  return (
    <>
      <div className=" lg:mx-24 md:mx-12 mx-0 lg:my-12 my-4">
        <div className="relative" id="home">
          <img
            src={backgroundImage2}
            alt="Hero Background"
            className="w-full lg:h-[60vh] h-[46vh] object-cover md:rounded-2xl"
          />

          <div className="absolute inset-0 bg-black opacity-10 rounded-2xl"></div>
          <div className="absolute inset-0 flex text-center items-center justify-center">
            <div className="md:hidden block">
              <h4 className="px-7 w-5/3 text-white text-center font-bold text-3xl mb-6">
                Its not just another home food app!
              </h4>
              <img
                src={mobileGroupImage}
                alt="mobileGroupImage"
                className="h-[21vh] mx-auto object-cover"
              />
              <p className="w-5/2 pu lg:mb-6 mb-1 lg:text-xl md:text-lg sm:text-md text-md leading-relaxed">
                Its an opportunity to empower millions of stay-at-home
                individuals.
              </p>
            </div>
          </div>

          <div className="absolute inset-0 flex md:flex-row flex-col justify-center">
            <div className="md:w-1/2 hidden md:block container ">
              <div className="box">
                <h1 className="text-white pt-10 font-semibold ">
                  Its not just another home food app!
                </h1>
                <p>
                  It's an opportunity to empower millions of stay-at-home
                  individuals.
                </p>

                <GetTheAppBtn className="" />
              </div>
            </div>
            <div className="box md:w-1/2 justify-center items-center ">
              <img
                src={mobileGroupImage}
                alt=""
                className="lg:h-[50vh] md:h-[40vh] object-cover m-auto hidden md:block"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto flex-col justify-center items-center text-center md:hidden block">
        {/* <p className="pu lg:mb-6 mb-4 lg:text-xl md:text-lg sm:text-md text-sm leading-relaxed">
          Its an opportunity to empower millions of stay-at-home individuals.
        </p> */}
        <GetTheAppBtn />
      </div>
    </>
  );
}

// import React from "react";
// import backgroundImage2 from "../assets/backgroundImage2.png";
// import mobileGroupImage from "../assets/mobileGroup1.png";
// import GetTheAppBtn from "./GetTheAppBtn";
// export default function Section5() {
//   return (
//     <>
//       <div className=" lg:mx-24 md:mx-12 mx-0 lg:my-12 my-4">
//         <div className="relative" id="home">
//           <img
//             src={backgroundImage2}
//             alt="Hero Background"
//             className="w-full lg:h-[60vh] h-[50vh] object-cover md:rounded-2xl"
//           />

//           <div className="absolute inset-0 bg-black opacity-10 rounded-2xl"></div>
//           <div className="absolute inset-0 flex items-center justify-center">
//             <div className="md:hidden block">
//               <h4 className="w-3/2 text-white text-center text-lg mb-2">
//                 Its not just another home food app!
//               </h4>
//               <img
//                 src={mobileGroupImage}
//                 alt="mobileGroupImage"
//                 className="h-[38vh] mx-auto object-cover"
//               />
//             </div>
//           </div>

//           <div className="absolute inset-0 flex md:flex-row flex-col justify-center">
//             <div className="md:w-1/2 hidden md:block container ">
//               <div className="box">
//                 <h3 className="">Its not just another home food app!</h3>
//                 <p className="">
//                   Its an opportunity to empower millions of stay-at-home
//                   individuals.
//                 </p>
//                 <GetTheAppBtn />
//               </div>
//             </div>
//             <div className="box md:w-1/2 justify-center items-center ">
//               <img
//                 src={mobileGroupImage}
//                 alt=""
//                 className="lg:h-[50vh] md:h-[40vh] object-cover m-auto hidden md:block"
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="container mx-auto flex-col justify-center items-center text-center md:hidden block">
//         <p className="pu lg:mb-6 mb-4 lg:text-xl md:text-lg sm:text-md text-sm leading-relaxed">
//           Its an opportunity to empower millions of stay-at-home individuals.
//         </p>
//         <GetTheAppBtn />
//       </div>
//     </>
//   );
// }
