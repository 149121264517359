import React from "react";
import GetTheAppBtn from "./GetTheAppBtn";
import Ellipse15 from "../assets/Ellipse15.webp";
import Ellipse16 from "../assets/Ellipse16.webp";
import { AiFillCheckCircle } from "react-icons/ai";
function Section1() {
  return (
    <section className="lg:my-16 my-10">
      <div className="flex md:flex-row flex-col">
        <div className="md:w-1/2 flex flex-col gap-4 md:items-start md:text-left md:mb-10 mb-0 lg:ml-16 md:ml-0 items-center text-center container">
          <h1
            id="orangeHeading"
            className="lg:text-5xl md:text-4xl sm:text-3xl text-2xl lg:mb-4 mb-0 leading-relaxed"
          >
            Why home cooked food?
          </h1>
          <p className="lg:mb-6 mb-0 lg:text-xl md:text-lg sm:text-md text-sm leading-relaxed">
            Have you ever eaten a restaurant meal and thought, 'This would be so
            much better if I made it at home?' Well, you're not alone! There are
            a lot of reasons why home-cooked food is better than restaurant
            food.
          </p>
          <p className="mb-6 lg:text-xl md:text-lg sm:text-md text-sm leading-relaxed hidden md:block">
            So next time you're thinking about eating out, remember all the
            reasons why home-cooked food is better. Save your money, eat
            healthier, and have more fun by eating at home!
          </p>

          <button
            className="w-1/2 mb-4 rounded-full py-3 px-5 text-xl my-2 text-white bg-orange-500 hidden md:block"
            style={{ fontWeight: "bold" }}
            onClick={() => {
              window.location.href = "#BeAHomeChef"; // Replace with the desired URL or anchor
            }}
          >
            Get The App
          </button>
        </div>
        <div className="md:w-1/2 py-4">
          <div className="relative container mx-auto">
            <img
              src={Ellipse15}
              alt="Hero Background"
              className="w-5/6 object-cover lg:ml-32 ml-20 hidden md:block"
            />
            <img
              src={Ellipse15}
              alt="Hero Background"
              className=" w-5/6 object-cover mx-auto ml-16 lg:ml-36 md:hidden block"
            />
            <div className="absolute inset-0 bg-black opacity-0"></div>
            <div className="absolute inset-0 flex flex-col items-start lg:text-xl md:text-lg sm:text-md text-sm leading-relaxed justify-around my-10 px-1 ">
              <div className="smallBox ml-10 inline-flex gap-2 lg:p-6 md:p-4 sm:p-3 p-2 lg:rounded-2xl md:rounded-xl rounded-lg">
                <AiFillCheckCircle className="mt-1 text-orange-500"></AiFillCheckCircle>
                <p>
                  Home-cooked food is <b>healthier</b>
                </p>
              </div>
              <div className="smallBox inline-flex gap-2 lg:p-6 md:p-4 sm:p-3 p-2 lg:rounded-2xl md:rounded-xl rounded-lg">
                <AiFillCheckCircle className="mt-1 text-orange-500"></AiFillCheckCircle>
                <p>
                  Home-cooked food <b>costs less</b>
                </p>
              </div>
              <div className="smallBox md:ml-10 ml-6 inline-flex gap-2 lg:p-6 md:p-4 sm:p-3 p-2 lg:rounded-2xl md:rounded-xl rounded-lg">
                <AiFillCheckCircle className="mt-1 text-orange-500"></AiFillCheckCircle>
                <p>
                  Home-cooked food is more <b>satisfying</b>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="items-center text-center ">
          <p className="md:hidden block px-8 mb-6 sm:text-md text-sm leading-relaxed">
            So next time you're thinking about eating out, remember all the
            reasons why home-cooked food is better. Save your money, eat
            healthier, and have more fun by eating at home!
          </p>
        </div>
      </div>
      <button
        class="md:hidden block mx-auto w-1/2 my-4 rounded-full py-0 px-5 text-lg font-semibold text-white bg-orange-500 responsive-button"
        onClick={() => {
          window.location.href = "#BeAHomeChef"; // Replace with the desired URL or anchor
        }}
        style={{ fontWeight: "bold" }}
      >
        Get The App
      </button>
    </section>
  );
}
export default Section1;
