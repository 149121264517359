import React from "react";

export default function GetTheAppBtn() {
  return (
    <>
      <button
        class="w-1/2 lg:my-6 mb-8 rounded-full py-1 px-5 text-2xl font-bold my-2 text-white bg-orange-500 responsive-button"
        style={{ fontWeight: "bold" }}
        onClick={() => {
          window.location.href = "#BeAHomeChef"; // Replace with the desired URL or anchor
        }}
      >
        Get The App
      </button>
    </>
  );
}
