import React from "react";
import Navbar from "./components/Navbar";
import Heros from "./components/Heros";
import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import Section3 from "./components/Section3";
import Section4 from "./components/Section4";
import Section5 from "./components/Section5";
import Carousel from "./components/Carousel";
import Footer from "./components/Footer";
import Test from "./components/Test";

function App() {
  return (
    <>
      <Navbar />
      <Heros />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      {/* <Carousel /> */}
      <Footer />
      {/* <Test /> */}
    </>
  );
}

export default App;
