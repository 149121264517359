import React, { useState } from "react";
import logo from "../assets/logo/Logo.png";
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-orange-500 fixed w-full z-50 p-4">
      <div className="flex md:flex-1 justify-between lg:mx-28 md:mx-10 mx-0">
        <a href="#home">
          <img className="lg:h-14 md:h-12 h-10" src={logo} alt="mimis" />
        </a>
        <div className="sm:hidden">
          <button
            onClick={toggleMenu}
            className="text-white hover:text-gray-200"
          >
            <svg
              className="h-8 w-8 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              {isOpen ? (
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M19 18H5V16H19V18ZM19 13H5V11H19V13ZM19 6H5V4H19V6Z"
                />
              ) : (
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4 6H20V8H4V6ZM4 11H20V13H4V11ZM20 18H4V16H20V18Z"
                />
              )}
            </svg>
          </button>
        </div>
        <div className="nav-links-container hidden sm:flex space-x-4 font-semibold">
          <a href="#whyMimis" className="my-auto nav-link px-4">
            Why Mimi’s
          </a>
          <a href="#BeAHomeChef" className="my-auto nav-link pl-4 pr-8">
            Be a home chef
          </a>
          <a href="#BeAHomeChef" className="my-auto nav-link duration-200">
            Download
          </a>
        </div>
      </div>
      {isOpen && (
        <div className="nav-links-container sm:hidden mt-2 text-center font-semibold ">
          <a href="#whyMimis" className="block my-auto nav-link mb-2 ">
            Why Mimi’s
          </a>
          <a href="#BeAHomeChef" className="block my-auto nav-link mb-2">
            Be a home chef
          </a>
          <a href="#BeAHomeChef" className="my-auto nav-link duration-200 mb-2">
            Download
          </a>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
