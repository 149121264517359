import React from "react";
import GetTheAppBtn from "./GetTheAppBtn";
import Group55 from "../assets/Group55.webp";
import Group56 from "../assets/Group56.webp";
function Section2() {
  return (
    <>
      <section className="lg:my-20 my-10">
        <div className="flex md:flex-row flex-col-reverse">
          <div className="md:w-1/2 md:container mx-auto">
            <img
              className="object-cover object-center hidden md:block"
              src={Group55}
              alt=""
            />
            <img
              className="object-cover object-center px-5 md:hidden block"
              src={Group56}
              alt=""
            />
          </div>
          <div className="md:w-1/2 md:items-start md:text-left md:mb-0 items-center text-center container">
            <div className=" container mx-auto">
              <h1
                id="orangeHeading"
                className="lg:text-5xl md:text-4xl sm:text-3xl text-2xl mb-4"
              >
                So why just not cook at home?
              </h1>
              <p className=" lg:mb-6 mb-0 lg:text-2xl md:text-xl text-lg font-bold">
                Sadly 'Netflix and cook' is not a thing
              </p>
              <p className="lg:mb-6 mb-0 lg:text-xl md:text-lg sm:text-md text-sm leading-relaxed">
                Fact is, not all of us have the time. We're busy with work,
                school, and other obligations.
              </p>
              <p className="lg:mb-10 mb-4 lg:text-xl md:text-lg sm:text-md text-sm leading-relaxed">
                And of course, not everyone is a natural cook. Some people are
                better at following other things than cooking (and thus
                preempting a kitchen disaster)
              </p>
              <button
                className="w-1/2 mb-4 rounded-full py-3 px-5 text-xl my-2 text-white bg-orange-500 hidden md:block"
                style={{ fontWeight: "bold" }}
                onClick={() => {
                  window.location.href = "#BeAHomeChef"; // Replace with the desired URL or anchor
                }}
              >
                Get The App
              </button>
            </div>
          </div>
        </div>
        <button class="md:hidden block mx-auto w-1/2 my-8 rounded-full py-3 px-5 text-lg font-semibold text-white bg-orange-500 responsive-button">
          Get The App
        </button>
      </section>
    </>
  );
}
export default Section2;
