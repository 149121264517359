import React from "react";
import logo from "../assets/logo/Logo.png";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa6";

export default function Footer() {
  return (
    <footer className="footerColor body-font ">
      <div className="md:max-w-7xl items-center px-5 py-14 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
        <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
          <a className="flex flex-col gap-5 font-medium items-center md:justify-start justify-between">
            <img className="mb-1 h-16" src={logo} alt="BrandLogo" />
            <p className="text-xl text-white text-center">
              Your home food network at your budget
            </p>
          </a>
        </div>
        <div className="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className=" text-center title-font font-medium text-white tracking-widest text-2xl mb-3">
              Download
            </h2>
            <nav className="flex flex-col-reverse justify-center items-center mb-10 gap-3">
              <a className="bg-gray-100 inline-flex py-2 px-4 rounded-lg items-center lg:ml-4 md:ml-0 ml-4 md:mt-4 mt-0 lg:mt-0 hover:bg-gray-200 focus:outline-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  className="w-6 h-6"
                  viewBox="0 0 512 512"
                >
                  <path d="M99.617 8.057a50.191 50.191 0 00-38.815-6.713l230.932 230.933 74.846-74.846L99.617 8.057zM32.139 20.116c-6.441 8.563-10.148 19.077-10.148 30.199v411.358c0 11.123 3.708 21.636 10.148 30.199l235.877-235.877L32.139 20.116zM464.261 212.087l-67.266-37.637-81.544 81.544 81.548 81.548 67.273-37.64c16.117-9.03 25.738-25.442 25.738-43.908s-9.621-34.877-25.749-43.907zM291.733 279.711L60.815 510.629c3.786.891 7.639 1.371 11.492 1.371a50.275 50.275 0 0027.31-8.07l266.965-149.372-74.849-74.847z"></path>
                </svg>
                <span className="ml-4 flex items-start flex-col leading-none">
                  <span className="text-xs text-gray-600 mb-1">GET IT ON</span>
                  <span className="title-font font-medium">Google Play</span>
                </span>
              </a>

              <a className="bg-gray-100 inline-flex py-2 px-4 rounded-lg items-center lg:ml-4 md:ml-0 ml-4 md:mt-4 mt-0 lg:mt-0 hover:bg-gray-200 focus:outline-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  className="w-6 h-6"
                  viewBox="0 0 305 305"
                >
                  <path d="M40.74 112.12c-25.79 44.74-9.4 112.65 19.12 153.82C74.09 286.52 88.5 305 108.24 305c.37 0 .74 0 1.13-.02 9.27-.37 15.97-3.23 22.45-5.99 7.27-3.1 14.8-6.3 26.6-6.3 11.22 0 18.39 3.1 25.31 6.1 6.83 2.95 13.87 6 24.26 5.81 22.23-.41 35.88-20.35 47.92-37.94a168.18 168.18 0 0021-43l.09-.28a2.5 2.5 0 00-1.33-3.06l-.18-.08c-3.92-1.6-38.26-16.84-38.62-58.36-.34-33.74 25.76-51.6 31-54.84l.24-.15a2.5 2.5 0 00.7-3.51c-18-26.37-45.62-30.34-56.73-30.82a50.04 50.04 0 00-4.95-.24c-13.06 0-25.56 4.93-35.61 8.9-6.94 2.73-12.93 5.09-17.06 5.09-4.64 0-10.67-2.4-17.65-5.16-9.33-3.7-19.9-7.9-31.1-7.9l-.79.01c-26.03.38-50.62 15.27-64.18 38.86z"></path>
                  <path d="M212.1 0c-15.76.64-34.67 10.35-45.97 23.58-9.6 11.13-19 29.68-16.52 48.38a2.5 2.5 0 002.29 2.17c1.06.08 2.15.12 3.23.12 15.41 0 32.04-8.52 43.4-22.25 11.94-14.5 17.99-33.1 16.16-49.77A2.52 2.52 0 00212.1 0z"></path>
                </svg>
                <span className="ml-4 flex items-start flex-col leading-none">
                  <span className="text-xs text-gray-600 mb-1">
                    Download on the
                  </span>
                  <span className="title-font font-medium">App Store</span>
                </span>
              </a>
            </nav>
          </div>
          <div className="text-center lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-white tracking-widest text-2xl mb-3">
              Learn
            </h2>
            <nav className="list-none mb-10 text-white text-md">
              <li>
                <a className="hover:text-orange-400 duration-200">
                  How it works
                </a>
              </li>
              <li>
                <a className="hover:text-orange-400 duration-200">
                  Food compliance
                </a>
              </li>
            </nav>
          </div>
          <div className="text-center lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-white tracking-widest text-2xl mb-3">
              Company
            </h2>
            <nav className="list-none mb-10 text-white text-md">
              <li>
                <a className="hover:text-orange-400 duration-200">About Us</a>
              </li>
              <li>
                <a className="hover:text-orange-400 duration-200">Contact</a>
              </li>
              <li>
                <a
                  href="mailto:contact@mimiskitchens.com"
                  className="hover:text-orange-400 text-center duration-200"
                >
                  contact@mimiskitchens.com
                </a>
              </li>
            </nav>
          </div>
          <div className="text-center lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-white tracking-widest text-2xl mb-3">
              Resources
            </h2>
            <nav className="list-none mb-10 text-white text-md">
              <li>
                <a className="hover:text-orange-400 duration-200">FAQs</a>
              </li>
              <li>
                <a className="hover:text-orange-400 duration-200">Home chef</a>
              </li>
              <li>
                <a className="hover:text-orange-400 duration-200">Foodie</a>
              </li>
              <li>
                <a className="hover:text-orange-400 duration-200">Partners</a>
              </li>
              <li>
                <a className="hover:text-orange-400 duration-200">Careers</a>
              </li>
            </nav>
          </div>
        </div>
      </div>
      <div className="bgf flex justify-center items-center">
        <div className="items-center w-full md:max-w-7xl">
          <div className="flex flex-col justify-center items-center  space-y-2 md:mx-[50px] md:flex-row md:justify-between">
            <div className="md:text-lg my-auto">
              <a className="hover:text-orange-500 duration-200">
                Privacy Policy
              </a>
              <a className="ml-3 hover:text-orange-500 duration-200">
                Terms of Service
              </a>
            </div>

            <div className="text-center flex flex-row ">
              <a className="m-2 inline-block border-2 border-black rounded-full text-black hover:border-orange-500 hover:text-orange-500 duration-200">
                <FaFacebookF className="m-2"></FaFacebookF>
              </a>
              <a className=" m-2 inline-block border-2 border-black rounded-full text-black hover:border-orange-500 hover:text-orange-500 duration-200">
                <FaInstagram className="m-2"></FaInstagram>
              </a>
              <a className="m-2 inline-block border-2 border-black rounded-full text-black hover:border-orange-500 hover:text-orange-500 duration-200">
                <FaTwitter className="m-2"></FaTwitter>
              </a>
              <a className="m-2 inline-block border-2 border-black rounded-full text-black hover:border-orange-500 hover:text-orange-500 duration-200">
                <FaLinkedinIn className="m-2"></FaLinkedinIn>
              </a>
              <a className=" m-2 inline-block border-2 border-black rounded-full text-black hover:border-orange-500 hover:text-orange-500 duration-200">
                <FaYoutube className="m-2"></FaYoutube>
              </a>
            </div>
            <div className="md:text-lg my-auto font-semibold">
              <span>© Mimi’s kitchen, Inc. 2023.</span>
              <span className="ml-3">All Rights Reserved</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
