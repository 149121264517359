import React, { useRef } from "react";
import Ellipse19 from "../assets/Ellipse19.webp";
import { useState } from "react";

export default function Section4() {
  const [showPopup, setShowPopup] = useState(false);

  async function Submit(e) {
    e.preventDefault();
    const formEle = document.querySelector("form");
    const formDatab = new FormData(formEle);
    await fetch(
      "https://script.google.com/macros/s/AKfycbwDkZut2r1GFk_TO2WULFmF3n8BuMosNEp0DGzyLkNGyJ5OyY6YASPIYg_ZBeBznXej/exec",
      {
        method: "POST",
        body: formDatab,
      }
    );
    setShowPopup(true);
    togglePopup();
  }
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <>
      <div
        className="container mx-auto flex md:flex-row flex-col mb-12"
        id="BeAHomeChef"
      >
        <div className="items-center text-center ">
          <h1
            id="orangeHeading"
            className="md:hidden block sm:text-3xl text-2xl leading-relaxed "
          >
            Are you a stay-at-home hustler with an ambition to make it big
            without leaving your loved ones behind?
          </h1>
        </div>

        <div class="md:w-1/2 container mx-auto">
          <img className="" src={Ellipse19} alt="" />
        </div>

        <div className="md:w-1/2 flex flex-col md:items-start md:text-left md:mb-0 items-center text-center ">
          <h1
            id="orangeHeading"
            className=" lg:text-5xl md:text-4xl sm:text-3xl text-2xl lg:mb-4 mb-0 leading-relaxed md:block hidden "
          >
            Are you a stay-at-home hustler with an ambition to make it big
            without leaving your loved ones behind?
          </h1>

          <p className=" lg:mb-6 mb-4 lg:text-xl md:text-lg sm:text-md text-sm leading-relaxed">
            Join Mimi's home-food network to make your dreams come true
          </p>
          <form className="form w-full md:w-2/3" name="form">
            <div className="mb-2">
              <input
                className="placeholder-middle inputBox w-2/3 py-3 pl-5 pr-5 text-xl rounded-xl lg:text-start text-center"
                placeholder="Phone number"
                type="tel"
                pattern="[6-9]{1}[0-9]{9}"
                id="Phone"
                name="Phone"
                required
              />
            </div>
            <div className="mb-2">
              <input
                className="placeholder-middle inputBox w-2/3 py-3 pl-5 pr-5 text-xl rounded-xl lg:text-start text-center"
                placeholder="City"
                type="text"
                name="City"
                id="City"
                required
              />
            </div>
            <div className="relative">
              <button
                class=" w-1/2 mx-auto lg:my-2 mb-4 rounded-full py-3 px-5 text-xl font-semibold my-2 text-white bg-orange-500 responsive-button"
                style={{ fontWeight: "bold" }}
                onClick={Submit}
              >
                Get Started
              </button>
              {showPopup && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                  <div className="bg-orange-400 text-Black rounded shadow-md p-4">
                    <p>
                      Thank you for submitting! Our team will get in touch with
                      you.
                    </p>
                    <button
                      className="mt-4 bg-white text-orange-500 px-4 py-2 rounded"
                      onClick={togglePopup}
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
