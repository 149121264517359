import React from "react";
import img1 from "../assets/groups/img1.webp";
import img2 from "../assets/groups/img2.webp";
import img3 from "../assets/groups/img3.webp";
import img4 from "../assets/groups/img4.webp";
import icon1 from "../assets/Clock.svg";
import icon2 from "../assets/Money Bag Rupee.svg";
import icon3 from "../assets/Heart Health.svg";
import icon4 from "../assets/Easy.png";
import GetTheAppBtn from "./GetTheAppBtn";
export default function Section3() {
  return (
    <>
      <div
        className=" mx-auto flex md:flex-row flex-col items-center lg:mb-20 mb-6"
        id="whyMimis"
      >
        <div className="md:w-1/2 flex flex-col md:items-start md:text-left md:mb-0 items-center text-center container">
          <div className=" flex flex-col md:items-start md:text-left md:mb-0 items-center text-center container">
            <h1
              id="orangeHeading"
              className="lg:text-5xl md:text-4xl sm:text-3xl text-2xl mb-4 leading-relaxed"
            >
              Why order Mimi's home-cooked delights?
            </h1>
            <p className="lg:mb-4 mb-2 lg:text-xl md:text-lg sm:text-md text-sm leading-relaxed">
              When it comes to home food apps, it's like a colorful buffet of
              choices, But hold your carrots, and let's ponder.
            </p>
            <div>
              <h3 className="lg:mb-4 mb-2 lg:text-xl md:text-lg sm:text-md text-sm leading-relaxed">
                Are these "choices" really:
              </h3>
              <ul className="lg:mb-4 mb-0 lg:text-xl md:text-lg sm:text-md text-sm leading-relaxed">
                <li className="flex mb-4 lg:gap-3 gap-2 ">
                  <img src={icon1} alt="" /> Time-Saving?
                </li>
                <li className="flex mb-4 lg:gap-3 gap-2">
                  <img src={icon2} alt="" /> Budget-friendly?
                </li>
                <li className="flex mb-4 lg:gap-3 gap-2">
                  <img src={icon3} alt="" />
                  Healthy?
                </li>
                <li className="flex mb-4 lg:gap-3 gap-2">
                  <img src={icon4} alt="" /> Effortless?
                </li>
              </ul>
            </div>

            <p className="lg:mb-10 mb-4 lg:text-xl md:text-lg sm:text-md text-sm leading-relaxed hidden md:block">
              We won't toot our own horn as the first or the greatest, because
              we'd rather let you taste for yourself. We're definitely worth a
              try.
            </p>
            {/* <GetTheAppBtn></GetTheAppBtn> */}
            <button
              className="w-1/2 mb-4 rounded-full py-3 px-5 text-xl my-2 text-white bg-orange-500 hidden md:block"
              style={{ fontWeight: "bold" }}
              onClick={() => {
                window.location.href = "#BeAHomeChef"; // Replace with the desired URL or anchor
              }}
            >
              Get The App
            </button>
          </div>
        </div>
        <div class=" container md:w-1/2 grid lg:grid-cols-2 sm:grid-cols-1">
          <div class="smallBox rounded-3xl my-3 lg:mx-4 mx-0">
            <div class="h-full flex flex-row sm:flex-col items-center sm:justify-start justify-center lg:text-center sm:text-left">
              <img
                alt="team"
                class="mx-auto pl-8 p-5 flex-shrink-0 rounded-lg lg:h-44 md:h-40 h-36  object-cover object-center sm:mb-0 mb-4"
                src={img1}
              />
              <div class="flex-grow sm:px-4 pl-8 p-2">
                <h2 class="text-lg">
                  <b>Time-saving</b>
                </h2>
                <p class="mb-4">
                  Do these apps actually save time or just make you snack on
                  patience?
                </p>
              </div>
            </div>
          </div>
          <div class="smallBox rounded-3xl my-3 lg:mx-4 mx-0">
            <div class="h-full flex flex-row sm:flex-col items-center sm:justify-start justify-center lg:text-center sm:text-left">
              <img
                alt="team"
                class="mx-auto p-5 flex-shrink-0 rounded-lg lg:h-44 md:h-40 h-36 object-cover object-center sm:mb-0 mb-4"
                src={img2}
              />
              <div class="flex-grow sm:px-4 p-2">
                <h2 class="text-lg">
                  <b>Budget-friendly</b>
                </h2>
                <p class="mb-4">
                  Are they as affordable as a value meal or as pricey as a
                  gold-plated caviar burger?
                </p>
              </div>
            </div>
          </div>
          <div class="smallBox rounded-3xl my-3 lg:mx-4 mx-0">
            <div class="h-full flex flex-row sm:flex-col items-center sm:justify-start justify-center lg:text-center sm:text-left">
              <img
                alt="team"
                class="mx-auto p-5 flex-shrink-0 rounded-lg lg:h-44 md:h-40 h-36 object-cover object-center sm:mb-0 mb-4"
                src={img3}
              />
              <div class="flex-grow sm:px-4 p-2">
                <h2 class="text-lg">
                  <b>Healthy</b>
                </h2>
                <p class="mb-4">
                  Is the food really healthy? Would their own family devour the
                  same grub?
                </p>
              </div>
            </div>
          </div>
          <div class="smallBox rounded-3xl my-3 lg:mx-4 mx-0">
            <div class="h-full flex flex-row sm:flex-col items-center sm:justify-start justify-center lg:text-center sm:text-left">
              <img
                alt="team"
                class="mx-auto p-5 flex-shrink-0 rounded-lg lg:h-44 md:h-40 h-36 object-cover object-center sm:mb-0 mb-4"
                src={img4}
              />
              <div class="flex-grow sm:px-4 p-2">
                <h2 class="text-lg">
                  <b>Effortless</b>
                </h2>
                <p class="mb-4">
                  Can anyone easily become a home-chef on these platforms, or do
                  they require countless forms and Ph.D?
                </p>
              </div>
            </div>
          </div>
          <div className=" flex flex-col md:items-start md:text-left md:mb-0 items-center text-center container">
            <p className="md:hidden block pt-4 lg:mb-10 mb-4 lg:text-xl md:text-lg sm:text-md text-sm leading-relaxed">
              We won't toot our own horn as the first or the greatest, because
              we'd rather let you taste for yourself. We're definitely worth a
              try.
            </p>
          </div>
          <button
            class="md:hidden block mx-auto w-1/2 lg:my-6 mb-8 rounded-full py-3 px-5 text-lg font-semibold my-2 text-white bg-orange-500 responsive-button"
            style={{ fontWeight: "bold" }}
            onClick={() => {
              window.location.href = "#BeAHomeChef"; // Replace with the desired URL or anchor
            }}
          >
            Get The App
          </button>
        </div>
      </div>
    </>
  );
}
