import React from "react";
import image1 from "../assets/image.webp";

const Heros = () => {
  return (
    <>
      <div className="md:relative" id="home2">
        <img
          src={image1}
          alt="Hero Background"
          className="sm:w-auto h-full md:h-screen object-cover"
        />

        {/* <div className="absolute inset-0 bg-black opacity-10"></div> */}
        <div className="md:absolute inset-0 flex items-center justify-center">
          <div className="w-3/4 text-center">
            <h1 className="text-white font-bold mb-6">
              Your home food
              <br />
              network at your budget
            </h1>
            <p className="heros text-white pt-8">
              Mimi’s is a hyper local social marketplace that connects home
              cooks to collaborate and sell their everyday meals and snacks to
              local customers
            </p>
          </div>
        </div>
      </div>
      <div
        className="sm:hidden relative"
        id="home1"
        style={{ backgroundColor: "#FFD5BC " }}
      >
        <img src={image1} alt="Hero Background" className="sm:w-auto h-full" />
        <div className="flex items-center justify-center">
          <div className="w-3/4 text-center">
            <h1 className="text-black font-bold mb-2 pt-5" id="orangeHeading">
              Your home food
              <br />
              network at your budget
            </h1>
            <p className="text-black pt-4 pb-6 font-bold text-sm">
              Mimi’s is a hyper local social marketplace that connects home
              cooks to collaborate and sell their everyday meals and snacks to
              local customers
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Heros;
